import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useLocation } from 'react-router-dom';

const LinkTab = (props) => {
  // No changes needed here, this component is fine as is.
  const navigate = useNavigate();

  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        navigate(props.to);
      }}
      {...props}
    />
  );
};

const NavTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  // Define the routes corresponding to the tabs
  const tabRoutes = ['/bentley_id_data', '/central_instance_data', '/ip_restrictions_data'];

  // Update the active tab based on the current URL
  useEffect(() => {
    const currentRoute = location.pathname;
    const tabIndex = tabRoutes.indexOf(currentRoute);
    if (tabIndex !== -1) {
      setValue(tabIndex);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Navigate to the new tab's corresponding URL
    navigate(tabRoutes[newValue]);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="nav tabs example"
      role="navigation"
      style={{ backgroundColor: '#FFF' }}
    >
      <LinkTab label="Bentley ID Orgs" to="/bentley_id_data" />
      <LinkTab label="Central Customers" to="/central_instance_data" />
      <LinkTab label="IP Restrictions" to="/ip_restrictions_data" />
    </Tabs>
  );
};

export default NavTabs;