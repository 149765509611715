import { createHandleChange, useTableData } from './commonTableFunctions';
import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import NavTabs from './NavTabs';
import { RenderTable } from './RenderTable';
import { currentDateDMY } from './commonTableFunctions';
import { getPermissionCookieValue, canEditField } from './commonTableFunctions';
import { AddNewIP } from './AddNewContent';
import { TextField } from '@mui/material';

export const fetchData = async () => {
    try {
      const { data } = await axios.get('/api/ip_restrictions_data/');
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

const IpRestrictionsTable = () => {
    const { data, setData, value, setValue } = useTableData(fetchData);
    const handleChange = useMemo(() => createHandleChange(setValue), [setValue]);

    const [isEditing, setisEditing] = useState(false);

    const permissions = getPermissionCookieValue();

    const columns = useMemo(() => [
      { 
        title: 'Organisation', 
        field: 'organisation',
        cellStyle: { backgroundColor: '#1D1F23', color: '#FFF' },
        editComponent: (props) => (
          // Ensures the text field is styled correctly
          <TextField
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            style: { color: '#FFF', backgroundColor: '#1D1F23' }
          }}
          sx={{
             // Removes the border when the field is not being edited
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
        />
        )
      },
      { 
        title: 'Platform Name', 
        field: 'platform_name'
      },
      { 
        title: 'Deployment Name', 
        field: 'deployment_name'
      },
      { 
        title: 'Allowed CIDR', 
        field: 'allowed_cidr'
      },
      { 
        title: 'Notes', 
        field: 'notes'
      },
      { 
        title: 'Active', 
        field: 'active'
      }
    ], []);

    useEffect(() => {
      if (isEditing === false) {
        const intervalId = setInterval(async () => {
          const newData = await fetchData();
          setData(newData);
        }, 60000); // Fetch data every 60 seconds
    
        return () => clearInterval(intervalId);
      }
    });

    const optionals = [];
    if (canEditField(permissions, 'cloudoperation') === 'always') {
      optionals.push(['enableAdding', AddNewIP]);
    }
    if (canEditField(permissions, 'cloudoperation') === 'always') {
      optionals.push('enableEditing');
    }

    return(
      <>
        <NavTabs value={value} handleChange={handleChange} />
        <RenderTable
          columns={columns}
          data={data}
          setData={setData}
          title="IP Restrictions"
          setisEditing={setisEditing}
          currentDateDMY={currentDateDMY}
          optionals={optionals}
        />;
      </>
    );
  };

  export default IpRestrictionsTable;