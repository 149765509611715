import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';


const ProtectedRoute = ({ element: Component }) => {
  const deleteCookie = (name) => {
    Cookies.remove(name, { path: '/' });
  };
  
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response) {
        const { status, config } = error.response;
  
        if (status === 404) {
          window.location.href = '/api/microsoft/auth-redirect/';
          
        }
      }
      return Promise.reject(error);
    }
  );

  const isAuthenticated = Cookies.get('is_authenticated');

  if (!isAuthenticated) {
      window.location.href = '/api/microsoft/auth-redirect/';
      return null; // Return null to avoid rendering anything while redirecting
  }

  return <Component />;
};


export default ProtectedRoute;