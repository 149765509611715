import { Box, FormControl, MenuItem, Select, InputAdornment, InputLabel, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './pieDataServices.css';
import { fetchData as fetchCentralData } from './CentralInstanceTable';
import { fetchData as fetchIPData } from './IpRestrictionsTable';

const postCentralData = async (newData) => {
  try {
    const notifications = [];
    const csrfToken = Cookies.get('csrftoken');
    const response = await axios.post('/api/central_instance_data/', newData,
      {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      }
    );
    if (response.status === 201) {
      notifications.push('Request successful.', `Created jira ticket ${response.data}.`);
    } else if (response.status === 200) {
      notifications.push('Cannot add to database, please get in touch with PIE team.', `Created jira ticket ${response.data}.`);
    } else {
      notifications.push('Error requesting for new instance. Please get in touch with PIE team.');
    }
    return notifications;
  } catch (error) {
    console.error('Error posting data:', error);
    return false;
  }
};

const postIPData = async (newData) => {
  try {
    const notifications = [];
    const csrfToken = Cookies.get('csrftoken');
    const response = await axios.post('/api/ip_restrictions_data/', newData,
      {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      }
    );
    if (response.status === 201) {
      notifications.push('IP restriction added successfully.');
    } else {
      notifications.push('Error adding IP to restriction list. Please get in touch with PIE team.');
    }
    return notifications;
  } catch (error) {
    console.error('Error posting data:', error);
    return false;
  }
};

export const AddNewInstance = ({setData}) => {
  const [open, setOpen] = useState(false);
  const initialFormData = {
      instanceType: '',
      trialDuration: '60', // Default trial duration to 60 days
      region: '',
      manageOrganisation: '',
      salesforceOpportunity: '',
      centralInstanceAdmin: '',
      centralInstanceAdminEmail: '',
      mainCustomerContact: '',
      mainCustomerContactEmail: '',
      seequentRep: '',
      seequentRepEmail: '',
      note: '',
      implementationDate: null,
      // Add other form fields as needed
  };
  const [formData, setFormData] = useState(initialFormData);
  const [notification, setNotification] = useState([]);
  const [displayedNotification, setDisplayedNotification] = useState([]);

  useEffect(() => {
    if (notification.length > 0) {
      const timer = setTimeout(() => {
        const newNotification = notification[0];
        setDisplayedNotification((prev) => [...prev, newNotification]);
        setNotification((prev) => prev.slice(1));
        setTimeout(() => {
          setDisplayedNotification((prev) => prev.filter((note) => note !== newNotification));
        }, 5000); // Remove the notification after 5 seconds
      }, 1000); // 1 second delay between notifications

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleClickOpen = () => {
      setFormData(initialFormData); // Reset form data
      setOpen(true);
  };
    
  const handleClose = () => {
      setOpen(false);
  };
  
  const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
  };

  const handleDateChange = (date) => {
      setFormData((prevData) => ({
          ...prevData,
          implementationDate: date,
      }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Exclude trialDuration if instanceType is not "Trial"
    const dataToSubmit = { ...formData };
    if (dataToSubmit.instanceType !== 'Trial') {
      delete dataToSubmit.trialDuration;
    }
    const result = await postCentralData(dataToSubmit);
    if (result) {
      handleClose();
      const data = await fetchCentralData();
      setData(data);
      setNotification(result);
    }
  };

  return (
    <>
      <div className="notification-container">
        {displayedNotification.map((note, index) => (
          <div key={index} className="notification fade-in-out">
            {note}
          </div>
        ))}
      </div>
      <Button variant="contained" sx={{ backgroundColor: 'grey' }} onClick={handleClickOpen}>
          Request New Instance
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Request New Instance</Typography>
              <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  }}
              >
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent>
              <form onSubmit={handleSubmit}>
                <Typography variant="h7">Region</Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel></InputLabel>
                  <Select
                    name="region"
                    value={formData.region}
                    onChange={handleFormChange}
                  >
                    <MenuItem value="Australia East">Australia East</MenuItem>
                    <MenuItem value="Canada Central">Canada Central</MenuItem>
                    <MenuItem value="Canada East">Canada East</MenuItem>
                    <MenuItem value="Western US">Western US</MenuItem>
                    <MenuItem value="Brazil South">Brazil South</MenuItem>
                    <MenuItem value="Western Europe">Western Europe</MenuItem>
                    <MenuItem value="South Africa North">South Africa North</MenuItem>
                    <MenuItem value="UK South">UK South</MenuItem>
                    <MenuItem value="South East Asia">South East Asia</MenuItem>
                    <MenuItem value="Australia Central">Australia Central</MenuItem>
                    <MenuItem value="East US">East US</MenuItem>
                    <MenuItem value="South Central US">South Central US</MenuItem>
                    <MenuItem value="West Central US">West Central US</MenuItem>
                    <MenuItem value="North Europe">North Europe</MenuItem>
                    {/* Add more regions as needed */}
                  </Select>
                </FormControl>
                <Typography variant="h7">Type</Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel></InputLabel>
                  <Select
                    name="instanceType"
                    value={formData.instanceType}
                    onChange={handleFormChange}
                  >
                    <MenuItem value="Commercial Teams">Commercial Teams</MenuItem>
                    <MenuItem value="Commercial Interprise">Commercial Interprise</MenuItem>
                    <MenuItem value="Trial">Trial</MenuItem>
                    <MenuItem value="Internal">Internal</MenuItem>
                    <MenuItem value="Partner">Partner</MenuItem>
                    <MenuItem value="E365">E365</MenuItem>
                    {/* Add more types as needed */}
                  </Select>
                </FormControl>
                {formData.instanceType === 'Trial' && (
                  <>
                    <Typography variant="h7">Trial duration</Typography>
                    <TextField
                      margin="normal"
                      name="trialDuration"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={formData.trialDuration}
                      onChange={handleFormChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                      }}
                    />
                  </>
                )}
                <Typography variant="h7">Go-live date</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 2, marginBottom: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={formData.implementationDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                    />
                  </LocalizationProvider>
                </Box>
                <Typography variant="h7">Manage organisation</Typography>
                <TextField
                margin="normal"
                name="manageOrganisation"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.manageOrganisation}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Salesforce opportunity</Typography>
                <TextField
                margin="normal"
                name="salesforceOpportunity"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.salesforceOpportunity}
                onChange={handleFormChange}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Central instance administrator</Typography>
                    <TextField
                      margin="normal"
                      name="centralInstanceAdmin"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.centralInstanceAdmin}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Email</Typography>
                    <TextField
                      margin="normal"
                      name="centralInstanceAdminEmail"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.centralInstanceAdminEmail}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Main customer contact</Typography>
                    <TextField
                      margin="normal"
                      name="mainCustomerContact"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.mainCustomerContact}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Email</Typography>
                    <TextField
                      margin="normal"
                      name="mainCustomerContactEmail"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.mainCustomerContactEmail}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Seequent representative</Typography>
                    <TextField
                      margin="normal"
                      name="seequentRep"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.seequentRep}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h7">Email</Typography>
                    <TextField
                      margin="normal"
                      name="seequentRepEmail"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={formData.seequentRepEmail}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Typography variant="h7">Notes</Typography>
                  <TextField
                    margin="normal"
                    name="note"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.note}
                    onChange={handleFormChange}
                    multiline
                    rows={4} // Set the initial number of rows
                    inputProps={{ maxLength: 300 }} // Set character limit
                  />
                  <Typography variant="caption" color="textSecondary" align="right" display="block">
                    {300 - formData.note.length} characters remaining
                  </Typography>
                </Box>
              </form>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} type="submit">Submit</Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export const AddNewIP = ({setData}) => {
  const [open, setOpen] = useState(false);
  const initialFormData = {
      organisation: '',
      platformName: '',
      deploymentName: '',
      allowedCIDR: '',
      notes: '',
      active: ''
      // Add other form fields as needed
  };
  const [formData, setFormData] = useState(initialFormData);
  const [notification, setNotification] = useState([]);
  const [displayedNotification, setDisplayedNotification] = useState([]);

  useEffect(() => {
    if (notification.length > 0) {
      const timer = setTimeout(() => {
        const newNotification = notification[0];
        setDisplayedNotification((prev) => [...prev, newNotification]);
        setNotification((prev) => prev.slice(1));
        setTimeout(() => {
          setDisplayedNotification((prev) => prev.filter((note) => note !== newNotification));
        }, 5000); // Remove the notification after 5 seconds
      }, 1000); // 1 second delay between notifications

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleClickOpen = () => {
      setFormData(initialFormData); // Reset form data
      setOpen(true);
  };
    
  const handleClose = () => {
      setOpen(false);
  };
  
  const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Exclude trialDuration if instanceType is not "Trial"
    const dataToSubmit = { ...formData };
    if (dataToSubmit.instanceType !== 'Trial') {
      delete dataToSubmit.trialDuration;
    }
    const result = await postIPData(dataToSubmit);
    if (result) {
      handleClose();
      const data = await fetchIPData();
      setData(data);
      setNotification(result);
    }
  };

  return (
    <>
      <div className="notification-container">
        {displayedNotification.map((note, index) => (
          <div key={index} className="notification fade-in-out">
            {note}
          </div>
        ))}
      </div>
      <Button variant="contained" sx={{ backgroundColor: 'grey' }} onClick={handleClickOpen}>
          Add New IP Restriction
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Add New IP Restriction</Typography>
              <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  }}
              >
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent>
              <form onSubmit={handleSubmit}>
                <Typography variant="h7">Organisation</Typography>
                <TextField
                margin="normal"
                name="organisation"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.organisation}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Platform Name</Typography>
                <TextField
                margin="normal"
                name="platformName"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.platformName}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Deployment Name</Typography>
                <TextField
                margin="normal"
                name="deploymentName"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.deploymentName}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Allowed CIDR</Typography>
                <TextField
                margin="normal"
                name="allowedCIDR"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.allowedCIDR}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Notes</Typography>
                <TextField
                margin="normal"
                name="notes"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.notes}
                onChange={handleFormChange}
                />
                <Typography variant="h7">Active</Typography>
                <TextField
                margin="normal"
                name="active"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.active}
                onChange={handleFormChange}
                />
              </form>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} type="submit">Submit</Button>
          </DialogActions>
      </Dialog>
    </>
  );
};