import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './pieDataServices.css';

import SeequentHeader from './SeequentHeader';
import BentleyidTable from './BentleyidTable';
import CentralInstanceTable from './CentralInstanceTable';
import IpRestrictionsTable from './IpRestrictionsTable';
import LoginFailed from './LoginFailed';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/bentley_id_data" />} />
        <Route path="/central_instance_data" element={<ProtectedRoute element={() => <div className="App"><SeequentHeader /><CentralInstanceTable /></div>} />} />
        <Route path="/bentley_id_data" element={<ProtectedRoute element={() => <div className="App"><SeequentHeader /><BentleyidTable /></div>} />} />
        <Route path="/ip_restrictions_data" element={<ProtectedRoute element={() => <div className="App"><SeequentHeader /><IpRestrictionsTable/></div>} />} />
        <Route path="/LoginFailed" element={<div className="App"><LoginFailed /></div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;